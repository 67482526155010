export enum AlarmType {
  Unknown = "Unknown",
  Acoustic = "Acoustic",
  Assistance = "Assistance",
  Movement = "Movement",
  Fire = "Fire",
  Door = "Door",
  DoorOpen = "DoorOpen",
  Doorbell = "Doorbell",
  Doctor = "Doctor",
  WirelessCall = "WirelessCall",
  WanderingDetection = "WanderingDetection",
  ExternalSystem = "ExternalSystem",
  GenericCall = "GenericCall",
  Medical = "Medical",
  Emergency = "Emergency",
  EmergencyCall = "EmergencyCall",
  Call = "Call",
  Robbery = "Robbery",
  Resuscitation = "Resuscitation",
  ServiceRequest = "ServiceRequest",
  Technical = "Technical",
  ToiletCall = "ToiletCall",
  PullOut = "PullOut",
  Fall = "Fall",
  OutOfBed = "OutOfBed",
  OutOfRoom = "OutOfRoom",
  PersonalSecurity = "PersonalSecurity",
  EmergencyAlarm = "EmergencyAlarm",
  IntentionOutOfBed = "IntentionOutOfBed",
}
