import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import { ColumnDefinition } from "components/table/columnDefinition";
import Table, { IRowItem } from "components/table/table";
import Permission from "features/autorisation/domain/models/permission";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import LinkableDevice from "features/device/linkable-device/domain/models/linkable-device";
import { useLazyReadLinkableDevicesQuery } from "features/device/linkable-device/domain/reducers/linkable-device.reducer";
import ObjectSort from "models/object-sort";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NestedKeyof } from "utils/nested-keyof-utils";
import Pagination from "models/pagination";
import "./linkable-devices-table.component.scss";
import DeviceFilter from "features/device/models/device-filter";
import { useSessionStorage } from "usehooks-ts";
import { FilterValueType } from "features/device/models/device-filter-value";
import { useAppDispatch } from "redux-base/store";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import ReadLinkableDevicesCommand from "features/device/linkable-device/domain/models/read-linkable-devices-command";
import DeviceFilters from "features/device/views/device-filters";
import { useLazyReadFilterValuesQuery } from "features/device/domain/reducers/device.reducer";
import useTableHook from "hooks/table-hook";
import { MonitoringType } from "components/monitoring/monitoring-type";
import { toMonitoringType } from "models/fault-type";
import MonitoringIcon from "components/monitoring/monitoring-icon.component";

interface IProps {
  organisationUnitId: string;
  onSelectedDevicesChanged: (devices: LinkableDevice[]) => void;
  devicesToHide: LinkableDevice[];
}

function LinkableDevicesTable(props: Readonly<IProps>): ReactElement {
  const linkableDevicesFilterKey = "linkable-devices-filters";
  const sessionStorageSortingKey = "linkable-devices-sorting";
  const searchBarSessionStorageKey = "linkable-devices-searchbar";

  const { t } = useTranslation("linkableDevice");
  const dispatch = useAppDispatch();

  const renderIcon = (device: LinkableDevice): ReactElement => {
    const monitoringType: MonitoringType | undefined = toMonitoringType(
      device.fault,
    );

    return monitoringType ? (
      <MonitoringIcon type={monitoringType} withTooltip visible size={16} />
    ) : (
      <></>
    );
  };

  const columns: ColumnDefinition<
    LinkableDevice,
    NestedKeyof<LinkableDevice>
  >[] = [
    { key: "name", label: t("linkableDevicesTable.column.name") },
    {
      key: "type",
      label: t("linkableDevicesTable.column.type"),
      renderCustomContentProvider: (device) => (
        <>{t(`deviceTypes.${device.type}`)}</>
      ),
      disableSort: true,
    },
    {
      key: "externalSystem.name",
      label: t("linkableDevicesTable.column.externalSystem"),
    },
    {
      key: "source",
      label: t("linkableDevicesTable.column.source"),
      disableSort: true,
    },
    {
      key: "fault",
      label: "",
      renderCustomContentProvider: renderIcon,
      disableSort: true,
      tableCellProps: {
        align: "left",
        width: "40px",
      },
    },
  ];

  const [
    linkableDeviceFiltersFromSessionStorage,
    setLinkableDeviceFiltersFromSessionStorage,
  ] = useSessionStorage<DeviceFilter[]>(linkableDevicesFilterKey, []);
  const [sortFromSessionStorage, setSortFromSessionStorage] = useSessionStorage<
    ObjectSort<LinkableDevice>
  >(sessionStorageSortingKey, { property: "name", isAscending: true });
  const [searchBarFromSessionStorage, setSearchBarFromSessionStorage] =
    useSessionStorage<string>(searchBarSessionStorageKey, "");

  const {
    currentPagination: [currentPagination, setCurrentPagination],
    currentSearchQuery: [currentSearchQuery],
    currentPage,
    currentRowsPerPage,
    setCurrentPage,
    handleOnPageChanged,
    handleOnRowsPerPageChanged,
    handleOnSearchChanged,
  } = useTableHook();

  const [currentFilters, setCurrentFilters] = useState<DeviceFilter[]>(
    linkableDeviceFiltersFromSessionStorage,
  );

  const [
    triggerReadLinkableDevicesQuery,
    {
      data: readLinkableDevicesData,
      isSuccess: readLinkableDevicesIsSuccess,
      isLoading: readLinkableDevicesIsLoading,
      error: readLinkableDevicesError,
    },
  ] = useLazyReadLinkableDevicesQuery();
  const [
    triggerReadFilterValuesQuery,
    {
      data: readFilterValuesData,
      error: readFilterValuesError,
      isSuccess: readFilterValuesIsSucces,
    },
  ] = useLazyReadFilterValuesQuery();

  const hiddenAndLiveDataMergedList =
    readLinkableDevicesData?.devices?.filter(
      (device) =>
        props.devicesToHide.findIndex(
          (deviceToHide) => deviceToHide.id === device.id,
        ) === -1,
    ) ?? [];

  function handleOnSortChanged(
    property: NestedKeyof<LinkableDevice>,
    isAscending: boolean,
  ) {
    setSortFromSessionStorage({
      isAscending: isAscending,
      property: property,
    });
    setCurrentPage(0);
    setCurrentPagination({
      skip: 0,
      take: currentRowsPerPage,
    });
  }

  function handleOnOptionsSelected(
    filterValueType: FilterValueType,
    keys: string[],
  ) {
    const newFilters = [...currentFilters];
    const indexOfFilterWithSameFilterValueType = newFilters.findIndex(
      (filter) => filter.filterValueType === filterValueType,
    );
    const deviceFilter: DeviceFilter = {
      filterValueType: filterValueType,
      values: keys,
    };

    if (indexOfFilterWithSameFilterValueType !== -1) {
      newFilters[indexOfFilterWithSameFilterValueType] = deviceFilter;
    } else {
      newFilters.push(deviceFilter);
    }

    handleOnFiltersChanged(newFilters);
  }

  function handleOnFiltersChanged(deviceFilter: DeviceFilter[]) {
    setLinkableDeviceFiltersFromSessionStorage(deviceFilter);
    setCurrentFilters(deviceFilter);
    setCurrentPage(0);
    handleOnPageChanged(0);
  }

  function clearFilters() {
    setLinkableDeviceFiltersFromSessionStorage([]);
    setCurrentFilters([]);
  }

  useEffect(() => {
    if (readLinkableDevicesError) {
      dispatch(
        setErrorMessage({
          error: readLinkableDevicesError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readLinkableDevicesError]);

  useEffect(() => {
    if (readFilterValuesError) {
      dispatch(
        setErrorMessage({
          error: readFilterValuesError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readFilterValuesError]);

  useEffect(() => {
    const paginationWithHiddenDevices = {
      skip: currentPagination.skip,
      take: currentPagination.take + props.devicesToHide.length,
    } as Pagination;

    const readDevicesCommand = {
      organisationUnitId: props.organisationUnitId,
      pagination: paginationWithHiddenDevices,
      sort: sortFromSessionStorage,
      filters: currentFilters,
      searchQuery: searchBarFromSessionStorage,
    } as ReadLinkableDevicesCommand;

    const readFilterValuesCommand = {
      organisationUnitId: props.organisationUnitId,
    };

    triggerReadLinkableDevicesQuery(readDevicesCommand);
    triggerReadFilterValuesQuery(readFilterValuesCommand);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sortFromSessionStorage,
    currentPagination,
    currentFilters,
    currentSearchQuery,
  ]);

  return (
    <AutorisationWrapper atLeastOnePermissionOf={[Permission.ReadDevice]}>
      <>
        {readLinkableDevicesIsLoading && <LoadingIndicator />}
        {readLinkableDevicesIsSuccess && readLinkableDevicesData && (
          <>
            {readFilterValuesIsSucces && readFilterValuesData && (
              <DeviceFilters
                onSearchValueChanged={(value: string) => {
                  handleOnSearchChanged(value);
                  setSearchBarFromSessionStorage(value);
                }}
                activeFilters={currentFilters}
                filterValues={readFilterValuesData.filterValues.filter(
                  (readFilterValue) =>
                    readFilterValue.filterValueType !== FilterValueType.Linked,
                )}
                monitoringValues={[]}
                activeMonitoringFilters={[]}
                onMonitoringFilterSelected={() => {}}
                onOptionsSelected={handleOnOptionsSelected}
                clearFilters={clearFilters}
                searchBarValue={searchBarFromSessionStorage}
              />
            )}
            <Table
              className="linkable-devices-table"
              data={hiddenAndLiveDataMergedList.map<IRowItem<LinkableDevice>>(
                (linkableDevice) => ({ data: linkableDevice }),
              )}
              columns={columns}
              rowsAreSelectable={true}
              onSelectedRowsChanged={(devices: LinkableDevice[]) =>
                props.onSelectedDevicesChanged(devices)
              }
              enablePagination={true}
              count={readLinkableDevicesData.total}
              onPageChanged={handleOnPageChanged}
              rowsPerPage={currentRowsPerPage}
              onSortChanged={handleOnSortChanged}
              page={currentPage}
              onRowsPerPageChanged={handleOnRowsPerPageChanged}
              rowIdentifier={(item: LinkableDevice) => item.id}
              initialOrderBy={sortFromSessionStorage.property}
              initialOrderDirection={
                sortFromSessionStorage.isAscending ? "asc" : "desc"
              }
            />
          </>
        )}
      </>
    </AutorisationWrapper>
  );
}

export default LinkableDevicesTable;
