import { Collapse, Switch, Typography } from "@mui/material";
import { useExternalSystemsContextProvider } from "features/external-system/context/external-systems-provider";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./external-system-settings-configuration.module.scss";
import Alert from "components/alert/alert.component";

const ExternalSystemSettingsConfiguration = (): JSX.Element => {
  const { t } = useTranslation("externalSystem");

  const { externalSystemDetailsHook } = useExternalSystemsContextProvider();

  const {
    control,
    formState: { dirtyFields },
  } = useFormContext();

  const isViewing = externalSystemDetailsHook.viewingMode === "viewing";
  const isEditing = externalSystemDetailsHook.viewingMode === "editing";

  const isAlertVisible =
    isEditing &&
    (dirtyFields.isSonevoApiEnabled ||
      dirtyFields.isAlarmmonitorEnabled ||
      dirtyFields.isAudioEnabled ||
      dirtyFields.isKadexApiEnabled ||
      dirtyFields.isIS32ApiEnabled);

  return (
    <div className={styles.container}>
      <Collapse in={isAlertVisible}>
        <div className={styles.explanationRow}>
          <Alert body={t("details.settings.configurationRemark")} />
        </div>
      </Collapse>
      <div className={styles.configurationRow}>
        <Typography variant="subtitle1">
          {t("details.information.automaticDeviceCreation")}
        </Typography>
        <Controller
          name="shouldCreateDevicesAutomatically"
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              type="checkbox"
              checked={field.value}
              disabled={isViewing}
            />
          )}
        />
      </div>
      <div className={styles.configurationRow}>
        <Typography variant="subtitle1">
          {t("details.settings.configuration.sonevoApi")}
        </Typography>
        <Controller
          name="isSonevoApiEnabled"
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              type="checkbox"
              checked={field.value}
              disabled={isViewing}
            />
          )}
        />
      </div>
      <div className={styles.configurationRow}>
        <Typography variant="subtitle1">
          {t("details.settings.configuration.alarmMonitor")}
        </Typography>
        <Controller
          name="isAlarmmonitorEnabled"
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              type="checkbox"
              checked={field.value}
              disabled={isViewing}
            />
          )}
        />
      </div>
      <div className={styles.configurationRow}>
        <Typography variant="subtitle1">
          {t("details.settings.configuration.audioModule")}
        </Typography>
        <Controller
          name="isAudioEnabled"
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              type="checkbox"
              checked={field.value}
              disabled={isViewing}
            />
          )}
        />
      </div>
      <div className={styles.configurationRow}>
        <Typography variant="subtitle1" className={styles.configurationTitle}>
          {t("details.settings.configuration.kadexApi")}
        </Typography>
        <Controller
          name="isKadexApiEnabled"
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              type="checkbox"
              checked={field.value}
              disabled={isViewing}
            />
          )}
        />
      </div>
      <div className={styles.configurationRow}>
        <Typography variant="subtitle1" className={styles.configurationTitle}>
          {t("details.settings.configuration.is32Api")}
        </Typography>
        <Controller
          name="isIS32ApiEnabled"
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              type="checkbox"
              checked={field.value}
              disabled={isViewing}
            />
          )}
        />
      </div>
    </div>
  );
};

export default ExternalSystemSettingsConfiguration;
