import { createApi } from "@reduxjs/toolkit/query/react";
import ClientSecret from "features/external-system/domain/models/client-secret";
import ExternalSystem from "features/external-system/domain/models/external-system";
import { baseQuery } from "redux-base/create-api-utils";
import { toDate } from "utils/date-utils";
import ExternalSystemMonitoringFilter from "../models/external-system-monitoring-filter";
import { ExternalSystemType } from "../models/external-system-type";
import { ReadExternalSystemsQuery } from "../models/read-external-systems-query";
import { ReadExternalSystemsResponse } from "../models/read-external-systems-response";
import ValidateExternalSystemIpAddressCommand from "../models/validate-external-system-ipaddress-command";
import ValidateExternalSystemNameCommand from "../models/validate-external-system-name-command";

const baseUrl = "/api/v1/ExternalSystem";

export const externalSystemApi = createApi({
  reducerPath: "externalSystem",
  baseQuery,
  tagTypes: ["ExternalSystem", "ExternalSystemMonitoringFilters"],
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    createSystem: build.mutation<ExternalSystem, ExternalSystem>({
      query: (body) => ({
        url: `${baseUrl}/CreateExternalSystem`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ExternalSystem"],
    }),
    readSystems: build.query<
      ReadExternalSystemsResponse,
      ReadExternalSystemsQuery
    >({
      query: (body) => ({
        url: `${baseUrl}/ReadExternalSystems`,
        method: "POST",
        body,
      }),
      providesTags: ["ExternalSystem"],
      transformResponse: (response: ReadExternalSystemsResponse) => {
        response.externalSystems = response.externalSystems.map(
          (externalSystem) => {
            let childExternalSystems = externalSystem.childExternalSystems;

            if (
              externalSystem.type === ExternalSystemType.IoTEdgeCluster &&
              childExternalSystems
            ) {
              childExternalSystems.sort((a, b) => {
                if (a.isActiveInCluster && !b.isActiveInCluster) {
                  return -1;
                } else if (!a.isActiveInCluster && b.isActiveInCluster) {
                  return 1;
                } else {
                  return 0;
                }
              });

              childExternalSystems = childExternalSystems.map(
                (childSystem) => ({
                  ...childSystem,
                  lastCommunicationDate: toDate(
                    childSystem.lastCommunicationDate,
                  ),
                }),
              );
            }

            return {
              ...externalSystem,
              childExternalSystems,
              lastCommunicationDate: toDate(
                externalSystem.lastCommunicationDate,
              ),
            };
          },
        );
        return response;
      },
    }),
    getMonitorFilters: build.query<ExternalSystemMonitoringFilter[], void>({
      query: () => ({
        url: `${baseUrl}/GetMonitoringFilters`,
        method: "GET",
      }),
      providesTags: ["ExternalSystemMonitoringFilters"],
    }),
    updateSystem: build.mutation<ExternalSystem, ExternalSystem>({
      query: (body) => ({
        url: `${baseUrl}/UpdateExternalSystem`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ExternalSystem"],
    }),
    deleteSystem: build.mutation<void, string>({
      query: (id) => ({
        url: `${baseUrl}/DeleteExternalSystem/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ExternalSystem"],
    }),
    generateSecrets: build.mutation<ClientSecret, string>({
      query: (externalSystemId) => ({
        url: `${baseUrl}/GenerateSecrets`,
        method: "POST",
        body: { externalSystemId },
      }),
      invalidatesTags: ["ExternalSystem"],
    }),
    validateExternalSystemName: build.mutation<
      void,
      ValidateExternalSystemNameCommand
    >({
      query: (body) => ({
        url: `${baseUrl}/ValidateExternalSystemName`,
        method: "POST",
        body,
      }),
    }),
    validateExternalSystemIpAddress: build.mutation<
      void,
      ValidateExternalSystemIpAddressCommand
    >({
      query: (body) => ({
        url: `${baseUrl}/ValidateExternalSystemIpAddress`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useCreateSystemMutation,
  useLazyReadSystemsQuery,
  useReadSystemsQuery,
  useLazyGetMonitorFiltersQuery,
  useUpdateSystemMutation,
  useDeleteSystemMutation,
  useGenerateSecretsMutation,
  useValidateExternalSystemNameMutation,
  useValidateExternalSystemIpAddressMutation,
} = externalSystemApi;
