import { Divider, Typography } from "@mui/material";
import { useExternalSystemsContextProvider } from "features/external-system/context/external-systems-provider";
import { ExternalSystemType } from "features/external-system/domain/models/external-system-type";
import ExternalSystemSettingsIoTEdgeClient from "features/external-system/views/external-system-details/external-system-settings/external-system-settings-clients/external-system-settings-iot-edge-client";
import ExternalSystemSettingsViewpointClient from "features/external-system/views/external-system-details/external-system-settings/external-system-settings-clients/external-system-settings-viewpoint-client";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ExternalSystemSettingsEspaClient from "./external-system-settings-clients/external-system-settings-espa-client";
import ExternalSystemSettingsIoTEdgeClusterClient from "./external-system-settings-clients/external-system-settings-iot-edge-cluster-client";
import ExternalSystemSettingsKadex from "./external-system-settings-kadex";
import ExternalSystemSettingsOfflinePortalCredentials from "./external-system-settings-offline-portal-credentials";
import ExternalSystemSettingsOrganisation from "./external-system-settings-organisation";
import styles from "./external-system-settings.module.scss";
import ExternalSystemSettingsConfiguration from "./external-system-settings-configuration";

const ExternalSystemSettings = (): JSX.Element => {
  const { t } = useTranslation("externalSystem");

  const { externalSystemDetailsHook } = useExternalSystemsContextProvider();

  const { watch } = useFormContext();
  const watchSystemType = watch("type");
  const watchIsAlarmmonitorEnabled = watch("isAlarmmonitorEnabled");
  const watchIsKadexApiEnabled = watch("isKadexApiEnabled");

  const isViewing = externalSystemDetailsHook.viewingMode === "viewing";
  const isCreating = externalSystemDetailsHook.viewingMode === "creation";
  const selectedSystem =
    externalSystemDetailsHook.currentSelectedExternalSystem;

  const hasSelectedType = ExternalSystemType.None !== watchSystemType;
  const isViewpoint = selectedSystem?.type === ExternalSystemType.Viewpoint;
  const isIoTEdge = selectedSystem?.type === ExternalSystemType.IoTEdge;
  const isIoTEdgeCluster =
    watchSystemType === ExternalSystemType.IoTEdgeCluster;
  const showIoTEdgeFields =
    watchSystemType === ExternalSystemType.IoTEdge ||
    watchSystemType === ExternalSystemType.IoTEdgeCluster;
  const isESPA = watchSystemType === ExternalSystemType.ESPA;
  const showSystemSettings =
    ((isCreating && isIoTEdgeCluster) || !isCreating) &&
    watchSystemType !== ExternalSystemType.NFC &&
    watchSystemType !== ExternalSystemType.BLEBeacon;

  if (!hasSelectedType) return <></>;

  return (
    <div className={`${styles.container} ${isViewing && styles.viewing}`}>
      <div
        className={styles.settingsItem}
        data-testid="externalSystemOrganisationContainer"
      >
        <Typography variant="h5">
          {t("details.settings.organisationUnitLabel")}
        </Typography>
        <div className={styles.settingsItemContent}>
          <ExternalSystemSettingsOrganisation />
        </div>
      </div>
      <Divider />
      {showSystemSettings && (
        <div className={styles.settingsItem}>
          <Typography variant="h5" className={styles.settingsTitle}>
            {t("details.settings.clientSettingsLabel")}
          </Typography>
          {isViewpoint && <ExternalSystemSettingsViewpointClient />}
          {isIoTEdge && <ExternalSystemSettingsIoTEdgeClient />}
          {isIoTEdgeCluster && <ExternalSystemSettingsIoTEdgeClusterClient />}
          {isESPA && <ExternalSystemSettingsEspaClient />}
        </div>
      )}
      {showIoTEdgeFields && (
        <>
          {!isCreating && <Divider />}
          <div className={styles.settingsItem}>
            <Typography variant="h5">
              {t("details.settings.configurationLabel")}
            </Typography>
            <ExternalSystemSettingsConfiguration />
          </div>
          <Divider />
          {watchIsAlarmmonitorEnabled && (
            <>
              <div className={styles.settingsItem}>
                <Typography variant="h5" className={styles.settingsTitle}>
                  {t("details.settings.offlinePortalCredentialsLabel")}
                </Typography>
                <ExternalSystemSettingsOfflinePortalCredentials />
              </div>
              <Divider />
            </>
          )}
          {watchIsKadexApiEnabled && (
            <div className={styles.settingsItem}>
              <Typography variant="h5" className={styles.settingsTitle}>
                {t("details.settings.kadexLabel")}
              </Typography>
              <ExternalSystemSettingsKadex />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ExternalSystemSettings;
