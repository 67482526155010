import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import { ColumnDefinition } from "components/table/columnDefinition";
import Table, { IRowItem } from "components/table/table";
import Permission from "features/autorisation/domain/models/permission";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import useScenario from "features/scenario/hooks/scenario-hook";
import styles from "features/scenario/linkable-scenario/views/linkable-scenarios-table.module.scss";
import Scenario from "features/scenario/models/scenario";
import ScenarioName from "features/scenario/views/scenario-name.component";
import { useTranslation } from "react-i18next";
import { NestedKeyof } from "utils/nested-keyof-utils";

interface Props {
  onSelectedScenariosChanged: (scenarios: Scenario[]) => void;
  scenariosToHide: Scenario[];
}

const LinkableScenariosTable = ({
  onSelectedScenariosChanged,
  scenariosToHide,
}: Readonly<Props>): JSX.Element => {
  const { t } = useTranslation("linkableScenarios");
  const { scenarios, isLoading, isSuccess } = useScenario();

  const columns: ColumnDefinition<Scenario, NestedKeyof<Scenario>>[] = [
    {
      key: "scenarioType",
      label: t("linkableScenariosTable.column.name"),
      disableSort: true,
      renderCustomContentProvider: (scenario) => (
        <ScenarioName scenarioType={scenario.scenarioType} />
      ),
    },
  ];

  const hiddenAndLiveDataMergedList =
    scenarios?.filter(
      (scenario) => !scenariosToHide.some((s) => s.id === scenario.id),
    ) ?? [];

  return (
    <AutorisationWrapper atLeastOnePermissionOf={[Permission.LinkScenario]}>
      <>
        {isLoading && <LoadingIndicator />}
        {isSuccess && scenarios && (
          <Table
            className={styles.heading}
            data={hiddenAndLiveDataMergedList.map<IRowItem<Scenario>>(
              (scenario) => ({ data: scenario }),
            )}
            columns={columns}
            rowsAreSelectable
            onSelectedRowsChanged={(scenarios: Scenario[]) =>
              onSelectedScenariosChanged(scenarios)
            }
            rowIdentifier={(item: Scenario) => item.id}
          />
        )}
      </>
    </AutorisationWrapper>
  );
};

export default LinkableScenariosTable;
