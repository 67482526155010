import { MenuItem, Switch } from "@mui/material";
import { useEmergencyAlarmSettingsContextProvider } from "features/emergency-alarm-settings/context/emergency-alarm-settings-provider";
import { ReactElement } from "react";
import EmergencyAlarmSettingDetailsHeader from "./emergency-alarm-setting-details-header";
import { Controller, FormProvider } from "react-hook-form";
import SettingsRow from "features/organisation/views/organisation-details/settings-row.component";
import { useTranslation } from "react-i18next";
import "./emergency-alarm-setting-details.component.scss";
import { EmergencyAlarmActivationMethod } from "features/emergency-alarm-settings/domain/models/emergency-alarm-activation-method";
import SelectField from "components/formFields/select-field.component";
import { EmergencyAlarmDeviceTriggerAction } from "features/emergency-alarm-settings/domain/models/emergency-alarm-device-trigger-action";
import { preAlarmDurationOptions } from "features/emergency-alarm-settings/domain/models/emergency-alarm-setting";
import Alert from "components/alert/alert.component";
import { EmergencyAlarmLocationType } from "features/emergency-alarm-settings/domain/models/emergency-alarm-location-type";

function EmergencyAlarmSettingDetails(): ReactElement {
  const { emergencyAlarmSettingsDetailsHook } =
    useEmergencyAlarmSettingsContextProvider();

  const { handleSubmit, control, watch } =
    emergencyAlarmSettingsDetailsHook.form;

  const { t } = useTranslation("emergencyAlarm");
  const watchActivationMethod = watch("activationMethod");
  const watchDivergentSettingEnabled = watch("divergentSettingEnabled");
  const watchPreAlarmEnabled = watch("preAlarmEnabled");
  const watchEmergencyAlarmEnabled = watch("emergencyAlarmEnabled");
  const viewingMode = emergencyAlarmSettingsDetailsHook.viewingMode;
  const { organisationUnitId } =
    emergencyAlarmSettingsDetailsHook?.currentSelectedEmergencyAlarmSettings ||
    {};

  return (
    <div
      className={`emergency-alarm-settings-details 
        ${viewingMode !== "none" && "is-open"}`}
    >
      <FormProvider {...emergencyAlarmSettingsDetailsHook.form}>
        <form
          onSubmit={handleSubmit(
            emergencyAlarmSettingsDetailsHook.submitEmergencyAlarmSetting,
          )}
          className="emergency-alarm-settings-details-form"
          data-testid="detailPopupForm"
        >
          <EmergencyAlarmSettingDetailsHeader />
          <div className="emergency-alarm-settings-details-content">
            <div>
              <Alert
                title={t("details.organisationSettingsExplanation.title")}
                body={t(
                  `details.organisationSettingsExplanation.${
                    organisationUnitId
                      ? "locationDescription"
                      : "organisationDescription"
                  }`,
                )}
                severity={organisationUnitId ? "none" : "info"}
              />
            </div>
            {organisationUnitId && (
              <SettingsRow
                text={t("details.divergentSetting")}
                formField={
                  <Controller
                    name="divergentSettingEnabled"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        {...field}
                        type="checkbox"
                        value={field.value}
                        checked={field.value}
                        disabled={
                          emergencyAlarmSettingsDetailsHook.viewingMode ===
                          "viewing"
                        }
                      />
                    )}
                  />
                }
              />
            )}
            <SettingsRow
              text={t("details.emergencyAlarm")}
              formField={
                <Controller
                  name="emergencyAlarmEnabled"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      {...field}
                      type="checkbox"
                      value={field.value}
                      checked={field.value}
                      disabled={
                        emergencyAlarmSettingsDetailsHook.viewingMode ===
                          "viewing" || !watchDivergentSettingEnabled
                      }
                    />
                  )}
                />
              }
            />
            {watchEmergencyAlarmEnabled && (
              <>
                <Controller
                  name="activationMethod"
                  control={control}
                  render={({ field }) => {
                    return (
                      <SelectField
                        {...field}
                        inputProps={{ "data-testid": "typeInput" }}
                        id="typeInput"
                        variant="outlined"
                        className="input-field"
                        label={t("details.activationMethod.title")}
                        disabled={
                          emergencyAlarmSettingsDetailsHook.viewingMode ===
                            "viewing" || !watchDivergentSettingEnabled
                        }
                      >
                        {Object.values(EmergencyAlarmActivationMethod).map(
                          (activationMethod) => (
                            <MenuItem
                              data-testid={`activation-method-${activationMethod}`}
                              key={`activationMethod-${activationMethod}`}
                              value={activationMethod}
                            >
                              {t(
                                `details.activationMethod.${activationMethod}`,
                              )}
                            </MenuItem>
                          ),
                        )}
                      </SelectField>
                    );
                  }}
                />
                {(watchActivationMethod ===
                  EmergencyAlarmActivationMethod.AppAndButton ||
                  watchActivationMethod ===
                    EmergencyAlarmActivationMethod.DeviceButton) && (
                  <Controller
                    name="deviceTriggerAction"
                    control={control}
                    render={({ field }) => {
                      return (
                        <SelectField
                          {...field}
                          inputProps={{ "data-testid": "typeInput" }}
                          id="typeInput"
                          variant="outlined"
                          className="input-field"
                          label={t("details.deviceTriggerAction.title")}
                          disabled={
                            emergencyAlarmSettingsDetailsHook.viewingMode ===
                              "viewing" || !watchDivergentSettingEnabled
                          }
                        >
                          {Object.values(EmergencyAlarmDeviceTriggerAction).map(
                            (triggerAction) => (
                              <MenuItem
                                data-testid={`device-triger-action${triggerAction}`}
                                key={`deviceTriggerAction-${triggerAction}`}
                                value={triggerAction}
                              >
                                {t(
                                  `details.deviceTriggerAction.${triggerAction}`,
                                )}
                              </MenuItem>
                            ),
                          )}
                        </SelectField>
                      );
                    }}
                  />
                )}
                <Controller
                  name="locationType"
                  control={control}
                  render={({ field }) => {
                    return (
                      <SelectField
                        {...field}
                        inputProps={{ "data-testid": "locationTypeInput" }}
                        id="locationTypeInput"
                        variant="outlined"
                        className="input-field"
                        label={t("details.locationType.title")}
                        disabled={
                          emergencyAlarmSettingsDetailsHook.viewingMode ===
                            "viewing" || !watchDivergentSettingEnabled
                        }
                      >
                        {Object.values(EmergencyAlarmLocationType).map(
                          (locationType) => (
                            <MenuItem
                              data-testid={`location-type-${locationType}`}
                              key={`locationType-${locationType}`}
                              value={locationType}
                            >
                              {t(`details.locationType.${locationType}`)}
                            </MenuItem>
                          ),
                        )}
                      </SelectField>
                    );
                  }}
                />
                <SettingsRow
                  text={t("details.preAlarm")}
                  formField={
                    <Controller
                      name="preAlarmEnabled"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          {...field}
                          type="checkbox"
                          value={field.value}
                          checked={field.value}
                          disabled={
                            emergencyAlarmSettingsDetailsHook.viewingMode ===
                              "viewing" || !watchDivergentSettingEnabled
                          }
                        />
                      )}
                    />
                  }
                />
                {watchPreAlarmEnabled && (
                  <Controller
                    name="preAlarmDurationS"
                    control={control}
                    render={({ field }) => {
                      return (
                        <SelectField
                          {...field}
                          inputProps={{
                            "data-testid": "preAlarmDurationSInput",
                          }}
                          id="preAlarmDurationSInput"
                          variant="outlined"
                          className="input-field"
                          label={t("details.preAlarmDurationS.title")}
                          disabled={
                            emergencyAlarmSettingsDetailsHook.viewingMode ===
                              "viewing" || !watchDivergentSettingEnabled
                          }
                        >
                          {preAlarmDurationOptions.map((duration) => (
                            <MenuItem
                              data-testid={`prealarm-duration-${duration}`}
                              key={`preAlarmDurationS-${duration}`}
                              value={duration}
                            >
                              {t(`details.preAlarmDurationS.${duration}`)}
                            </MenuItem>
                          ))}
                        </SelectField>
                      );
                    }}
                  />
                )}
                <SettingsRow
                  text={t("details.soundEnabled")}
                  formField={
                    <Controller
                      name="soundEnabled"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          {...field}
                          type="checkbox"
                          value={field.value}
                          checked={field.value}
                          disabled={
                            emergencyAlarmSettingsDetailsHook.viewingMode ===
                              "viewing" || !watchDivergentSettingEnabled
                          }
                        />
                      )}
                    />
                  }
                />
              </>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

export default EmergencyAlarmSettingDetails;
