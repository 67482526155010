export enum EventType {
  OutOfBed = "OutOfBed",
  InBed = "InBed",
  OutOfBedAlarm = "OutOfBedAlarm",
  OutOfRoom = "OutOfRoom",
  InRoom = "InRoom",
  OutOfRoomAlarm = "OutOfRoomAlarm",
  WirelessCall = "WirelessCall",
  PersonalSecurity = "PersonalSecurity",
  WanderingDetection = "WanderingDetection",
  Presence = "Presence",
  NoPresence = "NoPresence",
  Doorbell = "Doorbell",
  Call = "Call",
  Doctor = "Doctor",
  Fall = "Fall",
  Medical = "Medical",
  Movement = "Movement",
  PullOut = "PullOut",
  Resuscitation = "Resuscitation",
  Robbery = "Robbery",
  ServiceRequest = "ServiceRequest",
  Technical = "Technical",
  ToiletCall = "ToiletCall",
  Fire = "Fire",
  Assistance = "Assistance",
  Door = "Door",
  DoorOpen = "DoorOpen",
  DoorClosed = "DoorClosed",
  Heartbeat = "Heartbeat",
  EmergencyCall = "EmergencyCall",
  IntentionOutOfBed = "IntentionOutOfBed",
  IntentionOutOfBedAlarm = "IntentionOutOfBedAlarm",
  Intruder = "Intruder",
  IntruderAlarm = "IntruderAlarm",
}
