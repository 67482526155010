import { TextField, Typography } from "@mui/material";
import { useExternalSystemsContextProvider } from "features/external-system/context/external-systems-provider";
import ExternalSystem from "features/external-system/domain/models/external-system";
import { ExternalSystemType } from "features/external-system/domain/models/external-system-type";
import useExternalSystemValidation from "features/external-system/hooks/external-system-validation-hook";
import moment from "moment";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ExternalSystemMonitoringAlert from "../components/external-system-monitoring-alert";
import styles from "./external-system-information.module.scss";
import ExternalSystemSettingsType from "./external-system-settings/external-system-settings-type";

const ExternalSystemInformation = (): JSX.Element => {
  const { t } = useTranslation("externalSystem");

  const { externalSystemDetailsHook } = useExternalSystemsContextProvider();

  const {
    formState: { errors },
    control,
  } = useFormContext();
  const { validateName, validateVirtualIpAddress } =
    useExternalSystemValidation();

  const watchShouldCreateDevicesAutomatically =
    externalSystemDetailsHook.form.watch("shouldCreateDevicesAutomatically");
  const watchSystemType = externalSystemDetailsHook.form.watch("type");

  const registerOptions = {
    name: {
      required: t("details.information.requiredHelperText"),
      validate: validateName,
    },
    ipAddress: {
      required: t("details.information.requiredHelperText"),
      validate: validateVirtualIpAddress,
    },
  };

  const isViewing = externalSystemDetailsHook.viewingMode === "viewing";
  const isCreating = externalSystemDetailsHook.viewingMode === "creation";
  const isEditing = externalSystemDetailsHook.viewingMode === "editing";

  const hasSelectedType = ExternalSystemType.None !== watchSystemType;
  const showIPAddress =
    watchSystemType === ExternalSystemType.IoTEdge ||
    watchSystemType === ExternalSystemType.IoTEdgeCluster;
  const showCommunicationStatus =
    watchSystemType !== ExternalSystemType.NFC &&
    watchSystemType !== ExternalSystemType.BLEBeacon &&
    (isViewing || isEditing);
  const isIoTEdgeCluster =
    watchSystemType === ExternalSystemType.IoTEdgeCluster;

  const selectedSystem =
    externalSystemDetailsHook.currentSelectedExternalSystem;

  const formattedDeviceCreationTime = moment(
    selectedSystem?.createDevicesAutomaticallySince,
  )
    ?.add(1, "hour")
    .format("HH:mm");

  const textField = (name: string, label: string, multiline = false) => (
    <Controller
      name={name}
      control={control}
      rules={registerOptions[name as keyof typeof registerOptions]}
      render={({ field }) => (
        <TextField
          {...field}
          inputProps={{ "data-testid": `${name}Input`, maxLength: 255 }}
          id={`${name}Input`}
          label={label}
          variant="outlined"
          error={!!errors[name]}
          helperText={errors[name]?.message?.toString()}
          multiline={multiline}
          minRows={multiline ? 8 : undefined}
          maxRows={multiline ? 12 : undefined}
          fullWidth
        />
      )}
    />
  );

  const readOnlyField = (label: string, value: string) => (
    <div className={styles.informationItem}>
      <Typography variant="h5">{label}</Typography>
      <Typography variant="subtitle1">{value}</Typography>
    </div>
  );

  const communicationDate = (lastCommunicationDate?: Date) =>
    lastCommunicationDate
      ? t("details.information.statusLastCommunicationDateLabel", {
          lastCommunicationDate: moment(lastCommunicationDate)
            .format("DD-MM-YYYY HH:mm")
            .toString(),
        })
      : t("details.information.statusUnknownLabel");

  const clusterSystemStatus = (system: ExternalSystem) => {
    const { id, name, isActiveInCluster, lastCommunicationDate } = system;
    return (
      <div key={id} className={styles.statusItem}>
        <Typography variant="subtitle1">{name}</Typography>
        <Typography variant="subtitle1">
          {isActiveInCluster
            ? t("details.information.active")
            : t("details.information.passive")}
        </Typography>
        <Typography variant="subtitle1">
          {communicationDate(lastCommunicationDate)}
        </Typography>
      </div>
    );
  };

  return (
    <div className={`${styles.container} ${isViewing && styles.viewing}`}>
      {(isEditing || isCreating) && (
        <>
          <Typography variant="h2">
            {isCreating && t("details.createSystemTitle")}
            {isEditing && t("details.editSystemTitle")}
          </Typography>

          <ExternalSystemMonitoringAlert externalSystem={selectedSystem} />

          <div className={styles.informationItem}>
            <ExternalSystemSettingsType />
          </div>

          {hasSelectedType && (
            <>
              {textField("name", t("details.information.nameLabel"))}

              {showIPAddress &&
                textField("ipAddress", t("details.information.ipAddressLabel"))}

              {textField("note", t("details.information.noteLabel"), true)}
            </>
          )}
        </>
      )}
      {isViewing && (
        <>
          <Typography variant="h2" data-testid="externalSystemInformationName">
            {selectedSystem?.name}
          </Typography>

          <ExternalSystemMonitoringAlert externalSystem={selectedSystem} />

          {selectedSystem?.type &&
            readOnlyField(
              t("details.settings.typeLabel"),
              t(`details.settings.type.${selectedSystem?.type}`),
            )}

          {selectedSystem?.ipAddress &&
            readOnlyField(
              t("details.information.ipAddressLabel"),
              selectedSystem?.ipAddress,
            )}

          {selectedSystem?.note &&
            readOnlyField(
              t("details.information.noteLabel"),
              selectedSystem?.note,
            )}

          {watchShouldCreateDevicesAutomatically && (
            <Typography variant="subtitle1">
              {t("details.information.automaticDeviceCreationActiveUntill", {
                createDevicesAutomaticallySince: formattedDeviceCreationTime,
              })}
            </Typography>
          )}
        </>
      )}

      {showCommunicationStatus && (
        <div className={styles.informationItem}>
          <Typography variant="h5">
            {t("details.information.statusLabel")}
          </Typography>
          {isIoTEdgeCluster && selectedSystem?.childExternalSystems ? (
            selectedSystem?.childExternalSystems.map(clusterSystemStatus)
          ) : (
            <Typography variant="subtitle1">
              {communicationDate(selectedSystem?.lastCommunicationDate)}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

export default ExternalSystemInformation;
