import { Grid } from "@mui/material";
import OrganisationUnitScenario from "features/residents/residents-settings/domain/models/organisation-unit-scenario";
import OrganisationUnitScenarioIcon from "features/residents/residents-settings/views/resident-alarm-scenarios/organisation-unit-scenario-icon";
import { ScenarioType } from "models/scenario-type";

interface Props {
  organisationUnitScenarios: OrganisationUnitScenario[];
}

const ResidentAlarmScenarios = ({
  organisationUnitScenarios,
}: Readonly<Props>): JSX.Element => {
  const isMatchingScenarioType = (scenarioType: ScenarioType) =>
    organisationUnitScenarios.some((s) => s.scenarioType === scenarioType);

  const organisationUnitScenario = (scenarioType: ScenarioType) =>
    organisationUnitScenarios.find((s) => s.scenarioType === scenarioType);

  return (
    <Grid container gap={1.5}>
      {organisationUnitScenarios.map(({ id, scenarioType }) => (
        <Grid item key={id}>
          <OrganisationUnitScenarioIcon
            sx={{ fontSize: 16 }}
            organisationUnitScenario={organisationUnitScenario(scenarioType)}
            isVisible={isMatchingScenarioType(scenarioType)}
            scenarioType={scenarioType}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ResidentAlarmScenarios;
