import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";
import Constants from "style/constants";

interface IProps {
  disabled?: boolean;
  sx?: SxProps<Theme>;
  color?: string;
}

export default function IntruderIcon(props: Readonly<IProps>): ReactElement {
  const color =
    props.disabled !== undefined && props.disabled
      ? Constants.Colors.inactive
      : props.color ?? "#7342BD";

  return (
    <SvgIcon
      sx={props.sx}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.4109 11.5333H9.72727C9.17499 11.5333 8.72727 11.981 8.72727 12.5333V15.3333C8.72727 15.8856 8.27956 16.3333 7.72727 16.3333H3.54545C2.99317 16.3333 2.54545 15.8856 2.54545 15.3333V9.77273C2.54545 9.34596 2.19949 9 1.77273 9C1.34596 9 1 9.34596 1 9.77273V19.2273C1 19.654 1.34596 20 1.77273 20C2.19949 20 2.54545 19.654 2.54545 19.2273V18.8C2.54545 18.2477 2.99317 17.8 3.54545 17.8H13.55L13.9167 16.3333H11.2727C10.7204 16.3333 10.2727 15.8856 10.2727 15.3333V14C10.2727 13.4477 10.7204 13 11.2727 13H11.844C11.8657 12.3552 12.1368 11.8663 12.4109 11.5333Z"
        fill={color}
      />
      <path
        d="M15.2052 5.59352C15.2052 6.47327 15.9439 7.18703 16.8544 7.18703C17.7649 7.18703 18.5036 6.47327 18.5036 5.59352C18.5036 4.71376 17.7649 4 16.8544 4C15.9439 4 15.2052 4.71376 15.2052 5.59352Z"
        fill={color}
      />
      <path
        d="M19.6512 10.6164C19.6855 10.6297 19.7164 10.643 19.7508 10.6563L19.7542 10.6596L20.0291 10.7758C20.5926 11.0181 21.0255 11.4762 21.2213 12.0439L21.3106 12.3029C21.503 12.8573 22.1284 13.1594 22.7021 12.9735C23.2759 12.7876 23.5886 12.1867 23.3962 11.629L23.3068 11.37C22.9186 10.238 22.0528 9.31504 20.9224 8.83035L20.6475 8.71415C19.9329 8.40873 19.1598 8.24938 18.3765 8.24938C16.8441 8.24938 15.4629 9.13909 14.872 10.5035L14.3463 11.7252L13.6076 12.0805C13.0648 12.3427 12.8449 12.9801 13.1163 13.5047C13.3877 14.0292 14.0474 14.2417 14.5902 13.9794L15.5076 13.5379C15.8615 13.3652 16.1398 13.0764 16.291 12.7245L16.6208 11.9609L17.2839 14.1354L15.5832 15.9281C15.3976 16.124 15.2637 16.3597 15.1984 16.6187L14.4081 19.6762C14.2604 20.2439 14.6212 20.8216 15.2087 20.9643C15.7962 21.1071 16.394 20.7585 16.5418 20.1908L17.2976 17.266L19.7267 14.7064C20.2352 14.1719 20.4242 13.425 20.2318 12.7245L19.6512 10.6164Z"
        fill={color}
      />
      <path
        d="M21.6405 17.2129L20.7815 15.1413V15.1447C20.7059 15.2443 20.6269 15.3405 20.541 15.4302L19.1427 16.9042L19.6408 18.1059C19.7233 18.3051 19.847 18.4877 20.005 18.6404L22.1215 20.6888C22.551 21.1037 23.2484 21.1037 23.6779 20.6888C24.1074 20.2738 24.1074 19.5999 23.6779 19.1849L21.6405 17.2129Z"
        fill={color}
      />
      <circle cx="5.65" cy="13.65" r="1.65" fill={color} />
    </SvgIcon>
  );
}
