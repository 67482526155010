import { Button, Typography } from "@mui/material";
import LinkOffOutlinedIcon from "@mui/icons-material/LinkOffOutlined";
import { ViewingMode } from "utils/viewing-utils";
import { useTranslation } from "react-i18next";
import "./organisation-details-devices-table.component.scss";
import OrganisationUnitDevice from "features/organisation/domain/models/organisation-unit-device";
import { LinkOutlined } from "@mui/icons-material";
import { ReactElement, useEffect, useState } from "react";
import { MonitoringType } from "components/monitoring/monitoring-type";
import { toMonitoringType } from "models/fault-type";
import MonitoringIcon from "components/monitoring/monitoring-icon.component";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import { useAuth } from "features/authentication/providers/authentication.provider";
import Permission from "features/autorisation/domain/models/permission";
import { ColumnDefinition } from "components/table/columnDefinition";
import { NestedKeyof } from "utils/nested-keyof-utils";
import Table, { IRowItem } from "components/table/table";
import DeviceDetailsPopup from "features/device/device-details/views/device-details-popup.component";
import { DeviceDetailsProvider } from "features/device/device-details/providers/device-details-provider/device-details.provider";
import { useDevices } from "features/device/devices/hooks";
import usePrevious from "hooks/previous-hook";
import { useOrganisationUnitsProvider } from "features/organisation/context/organisation-units-provider";
import { useOrganisationUnitTreeContext } from "features/organisation/providers/organisation-unit-tree-provider";
import SendTestEventPopup from "features/device/send-test-event/views/send-test-event-popup.component";
import { useSendTestEventContextProvider } from "features/device/send-test-event/context/send-test-event-provider";

interface OrganisationUnitDeviceRowItem extends OrganisationUnitDevice {
  isLinked: boolean;
}

interface IProps {
  noDevicesText: string;
  linkedDevices: OrganisationUnitDevice[] | undefined;
  unlinkedDevices: OrganisationUnitDevice[] | undefined;
  viewingMode: ViewingMode;
  onUnlinkButtonClicked: (selectedDevice: OrganisationUnitDevice) => void;
  onRelinkButtonClicked: (selectedDevice: OrganisationUnitDevice) => void;
}

function OrganisationDetailsDevicesTable(
  props: Readonly<IProps>,
): ReactElement {
  const { t } = useTranslation("organisation");

  const { hasPermission } = useAuth();

  const [selectedDeviceId, setSelectedDeviceId] = useState<
    string | undefined
  >();

  const { openDeviceDetailsPopup, isDeviceDetailsPopupOpen } = useDevices();

  const { organisationUnitDetailsHook } = useOrganisationUnitsProvider();

  const { refetch } = useOrganisationUnitTreeContext();

  const previousIsDeviceDetailsPopupOpen = usePrevious(
    isDeviceDetailsPopupOpen,
  );

  const { isSendTestEventPopupOpen } = useSendTestEventContextProvider();

  useEffect(() => {
    if (previousIsDeviceDetailsPopupOpen && !isDeviceDetailsPopupOpen) {
      refetch?.();
      organisationUnitDetailsHook.refresh();
      setSelectedDeviceId(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeviceDetailsPopupOpen]);

  const renderIcon = (device: OrganisationUnitDevice): ReactElement => {
    const monitoringType: MonitoringType | undefined = toMonitoringType(
      device.fault,
    );

    return monitoringType ? (
      <MonitoringIcon type={monitoringType} withTooltip visible size={16} />
    ) : (
      <></>
    );
  };

  const renderLinkingAction = (device: OrganisationUnitDevice): ReactElement =>
    props.linkedDevices?.some(
      (linkedDevice) => linkedDevice.id === device.id,
    ) ? (
      <>
        {props.viewingMode !== "viewing" &&
          hasPermission(Permission.LinkDevice) && (
            <Button
              startIcon={<LinkOffOutlinedIcon />}
              onClick={() => props.onUnlinkButtonClicked(device)}
              variant="text"
              data-testid={`unlinkDeviceButtonFor${device.name}`}
            >
              {t("devicesTable.unlinkButtonText")}
            </Button>
          )}
      </>
    ) : (
      <>
        {props.viewingMode !== "viewing" &&
          hasPermission(Permission.LinkDevice) && (
            <AutorisationWrapper>
              <Button
                startIcon={<LinkOutlined />}
                onClick={() => props.onRelinkButtonClicked(device)}
                variant="text"
                data-testid={`relinkDeviceButtonFor${device.name}`}
              >
                {t("devicesTable.relinkButtonText")}
              </Button>
            </AutorisationWrapper>
          )}
      </>
    );

  const columns: ColumnDefinition<
    OrganisationUnitDeviceRowItem,
    NestedKeyof<OrganisationUnitDeviceRowItem>
  >[] = [
    {
      key: "name",
      label: t("devicesTable.column.name"),
      renderCustomContentProvider: (item) => (
        <Typography
          className={`${
            props.viewingMode === "viewing" ? "clickable-device" : ""
          } ${!item.isLinked ? "unlinked-device" : ""}`}
          variant="subtitle1"
          data-testid={`deviceNameTextFor${item.name}`}
        >
          {item.name}
        </Typography>
      ),
      disableSort: true,
    },
    {
      key: "fault",
      label: t("devicesTable.column.action"),
      renderCustomContentProvider: (item) => (
        <div
          className={`organisation-device-actions-row-container organisation-details-row-container-${
            props.viewingMode === "viewing" ? "viewing" : "editing"
          }`}
        >
          <div>{renderIcon(item)}</div>
          <div>{renderLinkingAction(item)}</div>
        </div>
      ),
      disableSort: true,
      tableCellProps: {
        className: props.viewingMode === "viewing" ? "viewing-mode" : "",
      },
    },
  ];

  return (props.linkedDevices && props.linkedDevices.length > 0) ||
    (props.unlinkedDevices && props.unlinkedDevices?.length > 0) ? (
    <>
      <Table
        className="organisation-details-devices-table"
        data={props
          .linkedDevices!.map(
            (linkedDevice) =>
              ({
                ...linkedDevice,
                isLinked: true,
              } as OrganisationUnitDeviceRowItem),
          )
          .concat(
            props.unlinkedDevices!.map(
              (unlinkedDevice) =>
                ({
                  ...unlinkedDevice,
                  isLinked: false,
                } as OrganisationUnitDeviceRowItem),
            ),
          )
          .map<IRowItem<OrganisationUnitDeviceRowItem>>((device) => ({
            data: device,
            isClickable: props.viewingMode === "viewing",
            showColorOnHover: props.viewingMode === "viewing",
          }))}
        columns={columns}
        rowIdentifier={(item) => item.id}
        onItemClick={(item) => {
          setSelectedDeviceId(item.id);
          openDeviceDetailsPopup(true);
        }}
      />
      {selectedDeviceId && (
        <DeviceDetailsProvider
          deviceId={selectedDeviceId}
          initialViewingMode={"viewing"}
        >
          <DeviceDetailsPopup />
        </DeviceDetailsProvider>
      )}
      {isSendTestEventPopupOpen && <SendTestEventPopup />}
    </>
  ) : (
    <Typography variant="subtitle1">{props.noDevicesText}</Typography>
  );
}

export default OrganisationDetailsDevicesTable;
