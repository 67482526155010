import { RegexExpressions } from "constants/regex/regex-expressions";
import { useTranslation } from "react-i18next";
import { ApiResponse, isOkResponse } from "redux-base/create-api-utils";
import { useExternalSystemsContextProvider } from "../context/external-systems-provider";
import {
  useValidateExternalSystemIpAddressMutation,
  useValidateExternalSystemNameMutation,
} from "../domain/reducers/external-system.reducer";

interface ExternalSystemValidationHook {
  validateName: () => Promise<boolean | string>;
  validateIpAddress: (ipAddress: string) => boolean | string;
  validateVirtualIpAddress: () => Promise<boolean | string>;
  validatePassword: (password: string) => boolean | string;
}

const useExternalSystemValidation = (): ExternalSystemValidationHook => {
  const { t } = useTranslation("externalSystem");

  const [validateExternalSystemName] = useValidateExternalSystemNameMutation();
  const [validateExternalSystemIpAddress] =
    useValidateExternalSystemIpAddressMutation();

  const { externalSystemDetailsHook: detailView } =
    useExternalSystemsContextProvider();

  const validateName = async (): Promise<boolean | string> => {
    const name = detailView.form.getValues("name")?.trim();
    if (!name) return true;

    const id = detailView.currentSelectedExternalSystem?.id;
    const result = (await validateExternalSystemName({
      id,
      name,
    })) as ApiResponse<void>;

    if (isOkResponse(result)) return true;

    return t("details.information.uniqueNameHelperText");
  };

  const validateVirtualIpAddress = async (): Promise<boolean | string> => {
    const ipAddress = detailView.form.getValues("ipAddress")?.trim();
    if (!ipAddress) return true;

    const regex = new RegExp(RegexExpressions.IP_ADDRESS);
    if (!regex.test(ipAddress)) {
      return t("details.information.invalidVirtualIpAddressHelperText");
    }

    const id = detailView.currentSelectedExternalSystem?.id;
    const result = (await validateExternalSystemIpAddress({
      id,
      ipAddress,
    })) as ApiResponse<void>;

    if (isOkResponse(result)) return true;

    return t("details.information.duplicateIpAddressHelperText");
  };

  const validateIpAddress = (ipAddress: string | undefined) => {
    ipAddress = ipAddress?.trim();
    if (!ipAddress) return true;

    const regex = new RegExp(RegexExpressions.IP_ADDRESS);
    if (!regex.test(ipAddress)) {
      return t("details.information.invalidIpAddressHelperText");
    }

    return true;
  };

  const validatePassword = (password: string) => {
    if (!password) return t("details.information.requiredHelperText");

    const regex = new RegExp(RegexExpressions.PASSWORD);
    if (!regex.test(password)) {
      return t(
        "details.settings.offlinePortalCredentials.invalidPasswordHelperText",
      );
    }

    return true;
  };

  return {
    validateName,
    validateIpAddress,
    validateVirtualIpAddress,
    validatePassword,
  };
};

export default useExternalSystemValidation;
