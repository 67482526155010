import AcousticConfiguration from "features/residents/residents-settings/domain/models/configurations/acoustic-configuration";
import BaseAlarmScenarionConfiguration from "features/residents/residents-settings/domain/models/configurations/base-alarm-scenario-configuration";
import BaseSettingScenarioConfiguration from "features/residents/residents-settings/domain/models/configurations/base-setting-scenario-configuration";
import DoorConfiguration from "features/residents/residents-settings/domain/models/configurations/door-configuration";
import OrganisationUnitScenario from "features/residents/residents-settings/domain/models/organisation-unit-scenario";
import AcousticScenarioConfiguration from "features/residents/residents-settings/views/resident-scenario-row/acoustic-scenario-configuration.component";
import AlarmScenarioConfiguration from "features/residents/residents-settings/views/resident-scenario-row/alarm-scenario-configuration.component";
import BedScenarioConfiguration from "features/residents/residents-settings/views/resident-scenario-row/bed-scenario-configuration.component";
import ConfigurationRow from "features/residents/residents-settings/views/resident-scenario-row/configuration-row";
import DoorScenarioConfiguration from "features/residents/residents-settings/views/resident-scenario-row/door-scenario-configuration";
import SettingScenarioConfiguration from "features/residents/residents-settings/views/resident-scenario-row/setting-scenario-configuration.component";
import { ScenarioType } from "models/scenario-type";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { jsonParseWithLowerCaseKeys } from "utils/string-utils";
import { ViewingMode } from "utils/viewing-utils";
import IntruderScenarioConfiguration from "./intruder-scenario-configuration";
import IntruderConfiguration from "../../domain/models/configurations/intruder-configuration";

interface Props {
  viewingMode: ViewingMode;
  index: number;
  organisationUnitScenario: OrganisationUnitScenario;
  originalOrganisationUnitScenario?: OrganisationUnitScenario;
  intentionOutOfBedEnabled: boolean;
}

interface ComponentProps {
  viewingMode: ViewingMode;
  index: number;
  organisationUnitScenario: OrganisationUnitScenario;
  originalOrganisationUnitScenario?: OrganisationUnitScenario;
  title: string;
  intentionOutOfBedEnabled: boolean;
  //Passing the translation function as prop is necessary to avoid calling the translation hook in the conditional components which causes https://react.dev/errors/300?invariant=300
  translationFunction: (key: string) => string;
}

const getParsedConfiguration = (configuration: string | undefined): any =>
  jsonParseWithLowerCaseKeys(configuration);

const GetRoomScenarioConfiguration = (
  props: Readonly<ComponentProps>,
): JSX.Element => {
  const baseConfiguration = getParsedConfiguration(
    props.organisationUnitScenario.configuration,
  ) as BaseAlarmScenarionConfiguration;
  return (
    <ConfigurationRow
      {...props}
      originalOrganisationUnitScenario={props.originalOrganisationUnitScenario!}
      scenarioTitle={props.title}
      showAlarmInfo={true}
      scenarioActiveAllDay={baseConfiguration.sendAlarmAllDay}
      timeFrames={baseConfiguration.timeFrames}
      alarmTimeOut={baseConfiguration.alarmTimeOut}
      isAlarmActive={baseConfiguration.isAlarmActive}
      isStatusActive={baseConfiguration.isStatusActive}
      subtitlePrefix={props.translationFunction(
        "scenarioConfiguration.scenarioActiveAllDay",
      )}
    >
      <AlarmScenarioConfiguration
        organisationUnitScenario={props.originalOrganisationUnitScenario!}
        scenarioTitle={props.title}
        index={props.index}
      />
    </ConfigurationRow>
  );
};

const GetBedScenarioConfiguration = (
  props: Readonly<ComponentProps>,
): JSX.Element => {
  const baseConfiguration = getParsedConfiguration(
    props.organisationUnitScenario.configuration,
  ) as BaseAlarmScenarionConfiguration;
  return (
    <ConfigurationRow
      {...props}
      originalOrganisationUnitScenario={props.originalOrganisationUnitScenario!}
      scenarioTitle={props.title}
      showAlarmInfo={true}
      scenarioActiveAllDay={baseConfiguration.sendAlarmAllDay}
      timeFrames={baseConfiguration.timeFrames}
      alarmTimeOut={baseConfiguration.alarmTimeOut}
      isAlarmActive={baseConfiguration.isAlarmActive}
      isStatusActive={baseConfiguration.isStatusActive}
      subtitlePrefix={props.translationFunction(
        "scenarioConfiguration.scenarioActiveAllDay",
      )}
    >
      <BedScenarioConfiguration
        organisationUnitScenario={props.originalOrganisationUnitScenario!}
        scenarioTitle={props.title}
        index={props.index}
        intentionOutOfBedEnabled={props.intentionOutOfBedEnabled}
      />
    </ConfigurationRow>
  );
};

const GetSettingScenarioConfiguration = (
  props: Readonly<ComponentProps>,
): JSX.Element => {
  const settingConfiguration = getParsedConfiguration(
    props.organisationUnitScenario.configuration,
  ) as BaseSettingScenarioConfiguration;
  return (
    <ConfigurationRow
      {...props}
      originalOrganisationUnitScenario={props.originalOrganisationUnitScenario!}
      scenarioTitle={props.title}
      showAlarmInfo={false}
      scenarioActiveAllDay={settingConfiguration.activeAllDay}
      timeFrames={settingConfiguration.timeFrames}
      subtitlePrefix={`${props.translationFunction(
        "scenarioConfiguration.scenarioAvailable",
      )} ${
        settingConfiguration.whenActiveAlarm
          ? props.translationFunction(
              "scenarioConfiguration.settingScenarioWhenActiveAlarm",
            )
          : ""
      }`}
      whenActiveAlarm={settingConfiguration.whenActiveAlarm}
      isAlarmActive={true}
    >
      <SettingScenarioConfiguration
        organisationUnitScenario={props.originalOrganisationUnitScenario!}
        scenarioTitle={props.title}
        index={props.index}
      />
    </ConfigurationRow>
  );
};

const GetAcousticScenarioConfiguration = (
  props: Readonly<ComponentProps>,
): JSX.Element => {
  const acousticConfiguration = getParsedConfiguration(
    props.organisationUnitScenario.configuration,
  ) as AcousticConfiguration;
  return (
    <ConfigurationRow
      {...props}
      originalOrganisationUnitScenario={props.originalOrganisationUnitScenario!}
      scenarioTitle={props.title}
      showAlarmInfo={true}
      scenarioActiveAllDay={acousticConfiguration.sendAlarmAllDay}
      temporaryAcousticConfiguration={
        acousticConfiguration.temporaryConfigurationEnabled
          ? acousticConfiguration.temporaryConfiguration ?? undefined
          : undefined
      }
      timeFrames={acousticConfiguration.timeFrames}
      isAlarmActive={true}
      subtitlePrefix={props.translationFunction(
        "scenarioConfiguration.scenarioActiveAllDay",
      )}
    >
      <AcousticScenarioConfiguration
        organisationUnitScenario={props.originalOrganisationUnitScenario!}
        scenarioTitle={props.title}
        index={props.index}
      />
    </ConfigurationRow>
  );
};

const GetDoorScenarioConfiguration = (
  props: Readonly<ComponentProps>,
): JSX.Element => {
  const doorConfiguration = getParsedConfiguration(
    props.organisationUnitScenario.configuration,
  ) as DoorConfiguration;
  return (
    <ConfigurationRow
      {...props}
      originalOrganisationUnitScenario={props.originalOrganisationUnitScenario!}
      scenarioTitle={props.title}
      showAlarmInfo={true}
      isAlarmActive={true}
      scenarioActiveAllDay={doorConfiguration.sendAlarmAllDay}
      timeFrames={doorConfiguration.timeFrames}
      subtitlePrefix={props.translationFunction(
        "scenarioConfiguration.scenarioActiveAllDay",
      )}
    >
      <DoorScenarioConfiguration
        organisationUnitScenario={props.originalOrganisationUnitScenario!}
        scenarioTitle={props.title}
        index={props.index}
      />
    </ConfigurationRow>
  );
};

const GetIntruderScenarioConfiguration = (
  props: Readonly<ComponentProps>,
): JSX.Element => {
  const configuration = getParsedConfiguration(
    props.organisationUnitScenario.configuration,
  ) as IntruderConfiguration;
  return (
    <ConfigurationRow
      {...props}
      originalOrganisationUnitScenario={props.originalOrganisationUnitScenario!}
      scenarioTitle={props.title}
      showAlarmInfo={true}
      isAlarmActive={true}
      scenarioActiveAllDay={configuration.sendAlarmAllDay}
      timeFrames={configuration.timeFrames}
      subtitlePrefix={props.translationFunction(
        "scenarioConfiguration.scenarioActiveAllDay",
      )}
    >
      <IntruderScenarioConfiguration
        organisationUnitScenario={props.originalOrganisationUnitScenario!}
        scenarioTitle={props.title}
        index={props.index}
      />
    </ConfigurationRow>
  );
};

export function ResidentScenarioRow(props: Readonly<Props>): ReactElement {
  const { t } = useTranslation("residentsSettings");

  return (
    <div className="resident-scenario-row-container">
      {props.originalOrganisationUnitScenario &&
        props.organisationUnitScenario.scenarioType ===
          ScenarioType.CurrentLocation &&
        GetSettingScenarioConfiguration({
          ...props,
          title: t("details.currentLocation"),
          translationFunction: t,
        })}
      {props.originalOrganisationUnitScenario &&
        props.organisationUnitScenario.scenarioType === ScenarioType.Bed &&
        GetBedScenarioConfiguration({
          ...props,
          title: t("details.leftBed"),
          translationFunction: t,
        })}
      {props.originalOrganisationUnitScenario &&
        props.organisationUnitScenario.scenarioType === ScenarioType.Room &&
        GetRoomScenarioConfiguration({
          ...props,
          title: t("details.leftRoom"),
          translationFunction: t,
        })}
      {props.originalOrganisationUnitScenario &&
        props.organisationUnitScenario.scenarioType === ScenarioType.Intruder &&
        GetIntruderScenarioConfiguration({
          ...props,
          title: t("details.intruder"),
          translationFunction: t,
        })}
      {props.originalOrganisationUnitScenario &&
        props.organisationUnitScenario.scenarioType === ScenarioType.Door &&
        GetDoorScenarioConfiguration({
          ...props,
          title: t("details.door"),
          translationFunction: t,
        })}
      {props.originalOrganisationUnitScenario &&
        props.organisationUnitScenario.scenarioType === ScenarioType.Video &&
        GetSettingScenarioConfiguration({
          ...props,
          title: t("details.video"),
          translationFunction: t,
        })}
      {props.originalOrganisationUnitScenario &&
        props.organisationUnitScenario.scenarioType === ScenarioType.Acoustic &&
        GetAcousticScenarioConfiguration({
          ...props,
          title: t("details.acoustic"),
          translationFunction: t,
        })}
    </div>
  );
}

export default ResidentScenarioRow;
